<template>
  <div class="home dark:bg-gray-800 dark:text-white xs:mx-4">
    <Title v-if="meta && meta.metaTitle">{{meta.metaTitle}}</Title>
    <Meta v-if="meta && meta.metaDescription" name="description" :content="meta.metaDescription" />
    <Spinner color="blue" v-if="pending"/>
    <p class="text-red" v-else-if="error">{{error}}</p>
    <div v-else-if="data && !pending && !error" >
      <EditorJsRenderer :data-as-string="data"></EditorJsRenderer>
    </div>
  </div>
</template>

<script setup>
import Spinner from "~/components/Spinner.vue";
import {useApi} from "~/src/api";
import EditorJsRenderer from "~/components/editorjs/EditorJsRenderer.vue";
import {onMounted, ref} from "vue";

const api = useApi();
const meta = ref(null);
const {data, pending, error, refresh} = await useAsyncData('load-home', async () => {
  const home = await api.homepage.fetch();
  meta.value = home.seo;
  return home.content;
})

onMounted(() => refresh())
</script>
